$unit:       16px;
$lineheight: 1.5 * $unit;
$ratio:      1.61803398875;

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Mono Regular'), local('Roboto-Mono-Regular'), url(../fonts/RobotoMonoRegular.woff) format('woff');
  font-display: swap; }
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Mono Italic'), local('Roboto-Mono-Italic'), url(../fonts/RobotoMonoItalic.woff) format('woff');
  font-display: swap; }
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Mono Bold'), local('Roboto-Mono-Bold'), url(../fonts/RobotoMonoBold.woff) format('woff');
  font-display: swap; }

@media (prefers-color-scheme: light) {
  html {
    --background: #fff;
    --text: #222;
    --faded: #aaa;
    --link: color(display-p3 0.85 0 0);
    --button: #000; } }

@media (prefers-color-scheme: dark) {
  html {
    --background: #000;
    --text: #ddd;
    --faded: #555;
    --link: color(display-p3 0.85 0 0);
    --button: #fff; }
  .side--mask--1 {
    filter: invert(1) !important; }
  .ux-at {
    img {
      filter: invert(1) !important; } } }
html {

  color: var(--text);
  background: var(--background); }
* {
  margin:  0;
  padding: 0;
  border:  none; }

a {
  color:           var(--link);
  text-decoration: none; }

sur {
  font-size:   1rem/$ratio;
  font-weight: bold;
  position:    relative;
  top:        -.5rem;
  margin-left: .1rem; }
sup {
  margin-right: 2px; }

figure {
  position:   relative;
  text-align: center;
  img {
    margin-bottom: 0; } }

@media only screen and (max-width: 899px) {
  .ux-at {
    display: none; } }

body {}
p, ol, ul, h1, h2, h3, h4,
img, video, pre, code, figure,
.post__time, .header, .category {
  margin-bottom: $ratio*1rem; }

html,
body,
code,
h2,h3,h4,h5,
pre,code,
.aboutme {
  font-family: "Roboto Mono", monospace, sans-serif;
  font-size:   $unit;
  line-height: 1em * $ratio; }

.page {
  padding:   2.7rem 3rem;
  display:   block;
  max-width: 44rem;
  position:  relative;
  z-index:   1;
  margin:    0 auto; }

.home {
  display: flex;
  flex-direction: column;
  gap: 3rem; }
.header {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  * {
    margin-bottom: 0; }
  @media only screen and (max-width: 575px) {
    display: none; } }

.button {
  background: var(--button);
  color:     var(--background);
  padding:   0.7rem 1.5rem 0.8rem;
  border-radius: 2rem;
  display:      inline-block;
  margin: 2rem 0 10rem; }


.aboutme {
  font-weight: normal; }

.ux-at {
  display: flex;
  gap: 4rem;
  align-items: flex-end;
  a {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;
    color: var(--faded); }
  img {
    height:  3rem;
    display: inline-block;
    margin: 0; }
  span {
    font-size: 1.5rem;
    color: var(--faded);
    position: relative;
    top: -0.125rem; } }
.postlink {
  margin-bottom: 1rem;
  display: block;
  &:hover {
    .postlink__title__arrow {
      opacity: 1; }
    .postlink__time {
      color: var(--text); } }
  .material-icons {
    position: relative;
    top:      7px; } }

.postlink__time,
.postlink__title {
  font-weight: normal;
  display:     inline; }

.sociallinks,
.postlink__time,
.category__header {
  color:       var(--text);
  font-weight: normal; }
.sociallinks {
  line-height: 2rem; }
.category__header {
  opacity: 1/$ratio; }

.postlink__title__arrow {
  opacity: 0; }

.post__title {
  font-size:     1rem * $ratio;
  margin-bottom: 2.5rem * $ratio;
  font-weight:   bold; }

.arrow-back {
  font-size:     1rem * $ratio; }
.post__intro {
  font-style:  italic;
  font-size:   .8rem;
  line-height: 1.25rem; }

.post__time {
  display: block;
  margin-bottom: 1.25rem * $ratio; }

.post__content {
  img,
  video {
    display:      block;
    margin-left:  auto;
    margin-right: auto;
    text-align:   center; } }

.side {
  width:    calc((100vw - 44rem - 2*3rem)/2);
  height:   100vh;
  position: fixed; }

.side--image {
  background-image: url(../img/janklosowski01.jpg);
  background-position: top center;
  background-size: cover;
  animation: show_image 500ms; }

@keyframes show_image {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

.side--mask {
  background-position: top right; }

.side--mask--1 {
  background-image: url(../img/mask.svg);
  background-size: 4px; }

.comments {
  padding-top: 2.2rem; }
